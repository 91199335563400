
.star-rating {
    height: $ratingSize / 5;
    position: relative;
    width: $ratingSize;

    &:before {
        background-image: url('../../images/ratings/star_empty.svg');
        background-repeat: repeat-x;
        background-size: $ratingSize / 5;
        bottom: 0;
        content: "";
        display: block;
        height: $ratingSize / 5;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: $ratingSize;
    }

    > span {
        display: block;
        text-indent: -1000rem;
        width: $ratingSize;
        height: $ratingSize / 5;
        position: absolute;
        overflow: hidden;
        width: calc(var(--rating) / 5 * 100%);

        &:before {
            background-image: url('../../images/ratings/star_filled.svg');
            background-repeat: repeat-x;
            background-size: $ratingSize / 5;
            bottom: 0;
            content: "";
            display: block;
            height: $ratingSize / 5;
            left: 0;
            position: absolute;
            right: 0;
            text-indent: 1000rem;
            top: 0;
        }
    }
}

.rating-wrapper,
.rating-wrapper-reversed {
    text-align: left;
    white-space: nowrap;

    .review-count {
        color: #575757;
        margin-left: 0.3rem;
        font-size: 1.2rem;
    }
}

.rating-wrapper-reversed {
    display: flex;
    flex-direction: column-reverse;
    .review-count {
        margin-left: auto;
        margin-right: auto;
    }
}

.visibility-hidden {
    visibility: hidden;
}

@media screen and (max-width: $desktop-width) {
    .star-rating {
        height: $ratingSizeMobile / 5;
        width: $ratingSizeMobile;

        &:before {
            background-size: $ratingSizeMobile / 5;
            width: $ratingSizeMobile;
        }

        > span {
            height: $ratingSizeMobile / 5;
            &:before {
                background-size: $ratingSizeMobile / 5;
            }
        }
    }

    .rating-wrapper-reversed {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;

        .review-count {
            margin: 0rem;
        }
    }
}
